export default {
    name: 'AdminPropertyListItem',
    props: {
        id: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: ''
        },
        views: {
            type: String,
            default: ''
        },
        date: {
            type: String,
            default: ''
        },
        adnumber: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: null
        },
        isVip: {
            type: Boolean,
            default: false
        },

    },
    computed: {
        dateFormatted() {
            const date = new Date(this.$props.date);
            return date.getFullYear() + '-' + date.getMonth().toString().padStart(2, '0') + '-' + date.getDate();
        }
    },
    methods: {
        async deleteProperty(id) {
            this.$emit('itemDeleted', id)
        }
    }
}